<template>
  <div class="content_container">
    <el-page-header
      @back="this.$util.goBack"
      content="收银台"
      style="line-height:50px"
    >
    </el-page-header>
    <div class="totalMoney">
      应付金额￥<i>{{$route.query.totalMoney}}</i>
    </div>
    <div class="list">
      <ul>
        <li
          v-for='(item,index) in menuItems'
          :key='index'
        >

          <el-radio
            v-model="radioVal"
            :label="item.value"
          > <img :src="item.img">
            {{item.name}}
          </el-radio>

        </li>

      </ul>
    </div>
    <div class="total">

      <el-button
        @click="toPay"
        block
        type="danger"
        class="normal-button"
      >
        <i>支付</i>

      </el-button>

    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";
import NavHeader from "@/components/layout/nav-header";

export default {
  components: { VueQr, NavHeader },
  data() {
    return {
      radioVal: "1",
      payway: "",
      menuItems: [
        {
          name: "支付宝支付",
          img: require("@/assets/images/zfbzf.jpg"),
          value: "1"
        },
        {
          name: "微信支付",
          img: require("@/assets/images/wxzf.jpg"),
          value: "3"
        }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    //   goBack() {
    //   this.$router.go(-1);
    // },
    toPay() {
      this.$put("/home/order/" + this.$route.query.order_master_id, {
        payment_type: this.radioVal,
        type: "pc"
      }).then(() => {
        if (this.radioVal == 3) {
          this.payway = "wx";
        }
        this.$router.push({
          path: "pay",
          query: {
            order_master_id: this.$route.query.order_master_id,
            payway: this.payway,
            type: "pc",
            totalMoney: this.$route.query.totalMoney
          }
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.totalMoney {
  text-align: right;
  background: #fff;
  @include center;
  height: 150px;
  margin-bottom: 10px;

  i {
    color: red;
    font-size: 26px;
    font-weight: bold;
  }
}
.total {
  text-align: center;

  width: 100%;
  box-sizing: border-box;
  height: 100px;
  line-height: 100px;
  background: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  .el-button {
    width: 200px;
  }
}
.list {
  margin-bottom: 5px;
  background: #fff;
  padding: 20px;
  li {
    vertical-align: middle;
    line-height: 45px;
    padding: 20px;
    @include space-between;
    @include align-center;
    img {
      width: 40px;
      height: 35px;
      vertical-align: middle;
    }
    input {
      display: flex;
      height: 45px;
      text-align: right;
    }
    span {
      height: 100%;
      @include align-center;
    }
    .sub {
      font-size: 12px;
    }
  }
  dl {
    padding: 20px;
    dt {
      line-height: 45px;
    }
  }
}
</style>
 
